// eslint-disable-next-line @nx/enforce-module-boundaries
import dynamic from 'next/dynamic';

export default {
  // Navigation
  FooterMenu: dynamic(() => import('@sitecore/components/content/Footer/FooterMenu')),
  Footer: dynamic(() => import('@sitecore/components/content/Footer/Footer')),
  // @todo remove this when sitecore has finished migrating the footer
  FooterNew: dynamic(() => import('@sitecore/components/content/Footer/Footer')),
  LanguageSelector: dynamic(() => import('@sitecore/components/content/LanguageSelector/LanguageSelector')),
  Logo: dynamic(() => import('@sitecore/components/eneco/ImageLink/ImageLink')),
  MainNavigation: dynamic(() => import('@apps/header/shared/multilabel/src/Header')),
  MegaMenu: dynamic(() => import('@sitecore/components/content/MegaMenu/MegaMenu')),
  MegaMenuV2: dynamic(() => import('@sitecore/components/content/MegaMenuV2/MegaMenuV2')),
  MegaMenuDivision: dynamic(() => import('@sitecore/components/content/MegaMenuDivision/MegaMenuDivision')),
  MessageBar: dynamic(() => import('@sitecore/components/content/MessageBar/MessageBar')),
  NotificationsBar: dynamic(() => import('@sitecore/components/content/NotificationsBar/NotificationsBar')),
  SubFooterMenu: dynamic(() => import('@sitecore/components/content/Footer/SubFooterMenu')),
  SocialMediaMenu: dynamic(() => import('@sitecore/components/content/Footer/SocialMediaMenu')),

  // Content components
  ContentCategories: dynamic(() => import('@sitecore/components/content/ContentCategories/ContentCategories')),
  ChatLinks: dynamic(() => import('@sitecore/components/content/ChatLinks/ChatLinks')),
  Accordion: dynamic(() => import('@sitecore/components/content/Accordion/Accordion')),
  Hero: dynamic(() => import('@sitecore/components/content/Hero/Hero')),
  NotificationBox: dynamic(() => import('@sitecore/components/content/NotificationBox/NotificationBox')),
  TopTasks: dynamic(() => import('@sitecore/components/content/TopTasks/TopTasks')),
  ContactCustomerService: dynamic(
    () => import('@sitecore/components/content/ContactCustomerService/ContactCustomerService'),
  ),
  IconList: dynamic(() => import('@sitecore/components/content/IconList/IconList')),
  Image: dynamic(() => import('@sitecore/components/content/Image/Image')),
  List: dynamic(() => import('@sitecore/components/content/List/List')),
  HeroCard: dynamic(() => import('@sitecore/components/content/HeroCard/HeroCard')),
  LinkCard: dynamic(() => import('@sitecore/components/content/LinkCard/LinkCard')),
  Price: dynamic(() => import('@sitecore/components/content/Price/Price')),
  SimpleTable: dynamic(() => import('@sitecore/components/content/SimpleTable/SimpleTable')),
  TextStreamer: dynamic(() => import('@sitecore/components/content/TextStreamer/TextStreamer')),
  WeDoenHetNuTestimonialsCard: dynamic(
    () => import('@sitecore/components/content/WeDoenHetNuTestimonialsCard/WeDoenHetNuTestimonialsCard'),
  ),
  ButtonLink: dynamic(() => import('@sitecore/components/content/ButtonLink/ButtonLink')),
  ButtonLineUp: dynamic(() => import('@sitecore/components/content/ButtonLineUp/ButtonLineUp')),

  // Other
  CookieWall: dynamic(() => import('@apps/cookiewall/nl/multilabel/src/CookieWall')),
  ErrorPage: dynamic(() => import('@sitecore/components/content/ErrorPage/ErrorPage')),
  GTM: dynamic(() => import('@apps/gtm/shared/multilabel/src')),
  MetaData: dynamic(() => import('@sitecore/components/system/MetaData/MetaData')),
  MetaTags: dynamic(() => import('@sitecore/components/system/MetaTags/MetaTags')),
  StructuredDataSchema: dynamic(() => import('@sitecore/components/system/StructuredDataSchema/StructuredDataSchema')),

  // Wrapper components
  Article: dynamic(() => import('@sitecore/components/content/Article/Article')),
  Centered: dynamic(() => import('@sitecore/components/content/Centered/Centered')),
  Section: dynamic(() => import('@sitecore/components/content/Section/Section')),
  LineUp: dynamic(() => import('@sitecore/components/content/LineUp/LineUp')),
  NavigationView: dynamic(() => import('@sitecore/components/content/NavigationView/NavigationView')),
  SplitView: dynamic(() => import('@sitecore/components/content/SplitView/SplitView')),
  Carousel: dynamic(() => import('@sitecore/components/content/Carousel/Carousel')),

  // Wrappable components
  ArticleCard: dynamic(() => import('@sitecore/components/content/ArticleCard/ArticleCard')),
  ContentCard: dynamic(() => import('@sitecore/components/content/ContentCard/ContentCard')),
  ProductCard: dynamic(() => import('@sitecore/components/content/ProductCard/ProductCard')),
  StickyNav: dynamic(() => import('@sitecore/components/content/StickyNav/StickyNav')),
  Table: dynamic(() => import('@sitecore/components/content/Table/Table')),
  TitleTextCTA: dynamic(() => import('@sitecore/components/content/TitleTextCTA/TitleTextCTA')),
  PersonalizedTitleTextCTA: dynamic(
    () => import('@sitecore/components/content/PersonalizedTitleTextCTA/PersonalizedTitleTextCTA'),
  ),
  Checklist: dynamic(() => import('@sitecore/components/content/Checklist/Checklist')),
  USPItem: dynamic(() => import('@sitecore/components/content/USPItem/USPItem')),
  Video: dynamic(() => import('@sitecore/components/content/Video/Video')),
  ShareOnSocials: dynamic(() => import('@sitecore/components/content/ShareOnSocials/ShareOnSocials')),

  // Campaign components
  WeDoenHetNuCard: dynamic(() => import('@sitecore/components/content/Road2Zero/WeDoenHetNuCard/WeDoenHetNuCard')),
  WeDoenHetNuTextImage: dynamic(
    () => import('@sitecore/components/content/Road2Zero/WeDoenHetNuTextImage/WeDoenHetNuTextImage'),
  ),

  // Forms
  GenericForm: dynamic(() => import('@sitecore/components/forms/GenericForm/GenericForm')),
  GenericFormAddressField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormAddress/GenericFormAddressField'),
  ),
  GenericFormNameField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormNameField/GenericFormNameField'),
  ),
  GenericFormParagraph: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormParagraph/GenericFormParagraph'),
  ),
  GenericFormSalutationField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormSalutation/GenericFormSalutation'),
  ),
  GenericFormRadioGroup: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormRadioGroup/GenericFormRadioGroup'),
  ),
  GenericFormCheckboxField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormCheckboxField/GenericFormCheckboxField'),
  ),
  GenericFormCheckboxGroup: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormCheckboxGroup/GenericFormCheckboxGroup'),
  ),
  GenericFormDateField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormDateField/GenericFormDateField'),
  ),
  GenericFormEmailInputField: dynamic(
    () =>
      import('@sitecore/components/forms/GenericForm/Components/GenericFormEmailInputField/GenericFormEmailInputField'),
  ),
  GenericFormNumberInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormNumberInputField/GenericFormNumberInputField'
      ),
  ),
  GenericFormPhoneNumberInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormPhoneNumberInputField/GenericFormPhoneNumberInputField'
      ),
  ),
  GenericFormInputField: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormInputField/GenericFormInputField'),
  ),
  GenericFormTextAreaInputField: dynamic(
    () =>
      import(
        '@sitecore/components/forms/GenericForm/Components/GenericFormTextAreaInputField/GenericFormTextAreaInputField'
      ),
  ),
  GenericFormInputSelect: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormInputSelect/GenericFormInputSelect'),
  ),
  GenericFormDivider: dynamic(
    () => import('@sitecore/components/forms/GenericForm/Components/GenericFormDivider/GenericFormDivider'),
  ),
};
